<script>
import { authMethods, notificationMethods } from "@/state/helpers";
import { required, minLength, sameAs } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      email: "",
      password: "",
      confPassword: "",
      resetToken: "",
      submitted: false,
      error: null,
      tryingToReset: false,
      isResetError: false
    };
  },
  validations: {
    password: { 
      required,
      minLength: minLength(6)
    },
    confPassword: { 
      required,
      minLength: minLength(6),
      sameAsPassword: sameAs('password')
    }
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    }
  },
  created() {
    document.body.classList.add("auth-body-bg");
    let parameters = this.$route.query;
    this.email = parameters.email;
    this.resetToken = parameters.resetToken
    if(!this.email || !this.resetToken){
      this.$router.push({name: 'login'})
    }
  },
  methods: {
    ...authMethods,
    ...notificationMethods,
    // Try to register the user in with the email, fullname
    // and password they provided.
    tryToReset() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        
        this.tryingToReset = true;
        this.error = null;
        
        return (
          this.changePassword({
            email: this.email,
            password: this.password,
            resetToken: this.resetToken
          })
            // eslint-disable-next-line no-unused-vars
            .then(token => {
              this.tryingToReset = false;
              this.isResetError = false;
            })
            .catch(error => {
              this.tryingToReset = false;
              this.error = error ? error : "";
              this.isResetError = true;
            })
        );
      }
    }
  }
};
</script>

<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <a href="/">
        <i class="mdi mdi-home-variant h2 text-white"></i>
      </a>
    </div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-4">
            <div class="authentication-page-content p-4 d-flex align-items-center min-vh-100">
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <div class="text-center">
                        <div>
                          <a href="/" class="logo">
                            <img src="@/assets/images/logo-dark.png" height="60" alt="logo" />
                          </a>
                        </div>

                        <h4 class="font-size-18 mt-4">Reset Password</h4>
                        <p class="text-muted">Reset your password to nextrack.</p>
                      </div>

                      <div class="p-2 mt-5">
                        <b-alert
                          variant="danger"
                          class="mt-3"
                          v-if="notification.message && notification.type == 'danger'"
                          show
                        >{{notification.message}}
                        </b-alert>

                        <form class="form-horizontal" @submit.prevent="tryToReset">
                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-mail-line auti-custom-input-icon" style="color: rgb(211, 0, 15)"></i>
                            <label for="password">Password</label>
                            <input
                              v-model="password"
                              type="password"
                              class="form-control"
                              id="password"
                              placeholder="Enter New password"
                              :class="{ 'is-invalid': submitted && $v.password.$error }"
                            />
                            <div v-if="submitted && $v.password.$error" class="invalid-feedback">
                              <span v-if="!$v.password.required">Password is required.</span>
                            </div>
                          </div>
                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-mail-line auti-custom-input-icon" style="color: rgb(211, 0, 15)"></i>
                            <label for="password">Confirm Password</label>
                            <input
                              v-model="confPassword"
                              type="password"
                              class="form-control"
                              id="confPassword"
                              placeholder="Re-enter password"
                              :class="{ 'is-invalid': submitted && $v.confPassword.$error }"
                            />
                            <div v-if="submitted && $v.confPassword.$error" class="invalid-feedback">
                              <span v-if="!$v.confPassword.required">Password is required.</span>
                            </div>
                          </div>

                          <div class="mt-4 text-center">
                            <button
                              class="btn btn-danger w-md waves-effect waves-light"
                              type="submit"
                              style="background-color: rgb(211, 0, 15)"
                            >Reset</button>
                          </div>
                        </form>
                      </div>

                      <div class="mt-5 text-center">
                        <p>
                          Don't have an account ?
                          <router-link
                            tag="a"
                            to="/login"
                            class="font-weight-medium"
                            style="color: rgb(211, 0, 15)"
                          >Log in</router-link>
                        </p>
                        <p>
                          © 2021 nextrack.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="authentication-bg">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>